@import "../../abstracts/variables";

.user__profile__container {
  background-color: #F5F5F5;
  display: flex;
  font-family: "Montserrat";
  min-height: 75vh;
  padding-left: 30px;
  @include respond(tab-port) {
    flex-direction: column;
  }
  @include respond(phone) {
    margin-top: 80px;
    padding: 0 10px;
  }
  & .user__profile__left__side {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    height: 75vh;
    justify-content: space-between;
    @include respond(tab-port) {
      height: 140px;
    }
    @include respond(phone) {
      margin-top: 15px;
    }
    & .user__profile__tabs {
      border-right: 1px solid #DCDCDC;
      height: 200px;
      padding-right: 90px;
      @include respond(tab-port) {
        height: auto;
        border: none;
      }
      @include respond(phone) {
        padding: 0;
      }
      & h1 {
        font-family: "Montserrat";
        font-size: 13px;
        margin-bottom: 10px;
        padding: 10px;
        // color: #1C4CCF;
        @include respond(tab-port) {
          display: inline-block;
        }
        &:hover {
          background-color: $secondaryhover;
          cursor: pointer;
        }
        &.active {
          background-color: $secondaryhover;
          color: #0E88F2;
        }
      }
    }
    .version{
      font-family: "Montserrat";
      font-size: 12px;
      font-weight: 600;
      color: $primary;
      padding-left: 30px;
    }
  }
  & .user__profile__right {
    margin-top: 30px;
    padding-left: 90px;
    width: 60%;
    @include respond(tab-port) {
      width: 100%;
      padding-left: 0;
    }
    & .user__profile__first__part {
      border-bottom: 1px solid #DCDCDC;
      padding-bottom: 50px;
    }
    & h1 {
      font-family: "Montserrat";
      font-size: 20px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #26353E;
    }
    & .edit__profile__picture__div {
      margin-top: 30px;
      display: flex;
      align-items: center;
      & img {
        width: 56px;
        height: 56px;
        border-radius: 50%;
        margin-right: 5px;
        object-fit: cover;
      }
      & h1 {
        font-family: "Montserrat";
        font-size: 13px;
        font-weight: 600;
        color: #1E8659;
        cursor: pointer;
      }
    }
    & .email__address__text {
      margin-top: 30px;
      font-size: 18px;
      font-weight: bold;
      color: #26353E;
    }
    & .email__address__text__and__button {
      margin-top: 20px;
      display: flex;
      justify-content: space-between;
      & h1 {
        font-size: 14px;
        font-weight: 500;
        color: #3B505D;
        @include respond(tab-land) {
          width: 100%;
        }
        & span {
          font-weight: bold;
        }
      }
      & h2 {
        font-family: "Montserrat";
        font-size: 14px;
        font-weight: 500;
        color: $secondary;
        // margin-left: 150px;
        cursor: pointer;
        padding: 0;
        text-decoration: underline;
        @include respond(tab-land) {
          margin-left: 20px;
        }
      }
    }
  }
  & .user__profile__second__part {
    margin-top: 50px;
    height: 200px;
    & .user__profile__second__part__passwords {
      min-width: 60%;
      display: flex;
      margin-top: 15px;
      flex-direction: column;
      & .user__profile__second__part__input {
        width: 263px;
        height: 50px;
        margin-right: 15px;
        outline: none;
      }
    }
    & .user__profile__second__part__forgot {
      margin-top: 20px;
      font-family: "Montserrat";
      font-size: 11px;
      color: #7D8C96;
      & span {
        cursor: pointer;
        color: $secondary;
      }
    }
  }
}

.user__profile__left__side__page {
  display: flex;
  justify-content: flex-start;
  gap: 35px;
  align-items: center;
  & h1 {
    font-family: "Montserrat";
    font-style: normal;
    color: $primary;
    font-weight: 700;
    font-size: 24px;
    margin: 0;
  }
}
.user__public__profile__right {
  margin-left: 90px;
  margin-top: 30px;
  @include respond(tab-port) {
    margin-left: 0;
  }
  & h1 {
    font-family: "Montserrat";
    font-size: 20px;
    font-weight: bold;
    color: #26353E;
  }
  & .user__public__profile__right__inputs {
    display: flex;
    width: 70%;
    flex-wrap: wrap;
    padding-top: 30px;
    @include respond(phone) {
      width: 100%;
    }
    .form_input {
      @include respond(phone) {
        width: 100%;
      }
    }
    & .user__public__profile__right__single__input {
      width: 270px;
      height: 50px;
      margin-right: 20px;
      margin-bottom: 10px;
      @include respond(phone) {
        width: 100%;
      }
    }
    & .user__public__profile__right__disabled {
      width: 270px;
      height: 50px;
      margin-right: 20px;
      margin-bottom: 10px;
      background-color: #E3E6E8 !important;
      @include respond(phone) {
        width: 100%;
      }
    }
  }
}
.user__settings-container {
  display: flex;
  gap: 30px;
  width: 100%;
  &-tabs {
    display: flex;
    .client-settings__tabs {
      display: flex;
      flex-direction: column;
      padding-right: 30px;
      border-right: 1px solid #CECECE;
      height: calc(100vh - 160px);
      min-width: 240px;
      .tab {
        padding: 10px 20px;
        background-color: #fff;
        span {
           color: $primary;
           font-weight: 600;
           padding: 10px 20px;
           display: flex;
           font-family: "Montserrat";
           background-color: #fff;
           cursor: pointer;
        }
        &.active {
          span {
            border-radius: 5px;
            background: $primary;
            color: #fff;
          }
        }
        &:first-of-type {
          border-top-left-radius: 8px;
          border-top-right-radius: 8px;
        }
        &:last-of-type {
          border-bottom-left-radius: 8px;
          border-bottom-right-radius: 8px;
        }
      }
    }
  }
}
.edit__user__logo__div-clientSett {
  margin-top: 15px;
  background-color: #f5f5f5;
  border: 2px dashed #E8E8E8;
  border-radius: 8px;
  width: 540px;
  padding: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.edit__user__logo__content {
  display: inline-block;
  position: relative;
}
.client_setting_brand_logo{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  &_content{
    background-color: $primary;
    color: white;
    border-radius: 8px;
    display: flex;
    align-items: center;
    gap: 20px;
    padding: 10px 30px;
  }
  span{
    font-size: 16px;
    font-weight: 600;
    font-family: "Montserrat";
  }
}
.edit__user__logo__content img {
  object-fit: contain;
  background-color: #fff;
  border-radius: 8px;
  padding: 10px;
}
.edit__user__logo__imgicon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.input__file__component__empty {
  width: 100%;
  height: 97%;
  position: absolute;
  top: 49%;
  left: 50%;
  transform: translate(-50%, -50%);
  label {
    width: 100%;
    height: 100%;
    display: block;
  }
}
.edit__user__logo__title {
  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 600;
  color: #252525;
}
.client__settings__title {
  font-family: "Montserrat";
  font-size: 20px;
  font-weight: bold;
  color: #26353E;
  align-self: center;
}
.client__settings__page__header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.client__settings__save__button {
  width: 137px;
  height: 46px;
  padding: 14px 54px;
  background-color: #1E8659;
  color: white;
  font-family: "Montserrat";
  font-size: 13px;
  font-weight: 500;
}
.saveButton {
  width: 36px;
  height: 36px;
  // padding: 14px 54px;
  background-color: $primary;
  color: white;
  font-family: "Montserrat";
  font-size: 13px;
  font-weight: 500;
  position: absolute;
  top: 12px;
  right: 30px;
  display: flex;
  justify-content: center;
  @include respond(phone) {
    top: 110px;
    left: 120px;
  }
  align-items: center;
  &.disabled {
    background-color: #329B6D;
    cursor: not-allowed;
  }
  &__fileDisabled {
    background-color: #5BA887;
    cursor: not-allowed;
  }
}
.clientSettings__button__loader {
  height: 20px;
  width: 20px;
}
.language__change {
  display: flex;
  min-width: none !important;
  width: fit-content;
  font-family: "Montserrat";
  background-color: #979797;
  color: white;
  border-radius: 5px;
  font-weight: 600;

  &.templates {
    width: 200px;
    padding: 13px;
    & div {
      cursor: pointer;
      height: 30px !important;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 120px;
    }
  }
  & .active {
    // background-color: #1C4CCF !important;
    // background: linear-gradient(119.24deg, #981596 0%, #1C4CCF 100%) !important;
    background: $primary;
    color: white;
  }
  &-de {
    cursor: pointer;
    height: 30px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 70px;
    border-radius: 0px 5px 5px 0px;
    & p {
      font-family: "Montserrat";
    }
  }
  &-en {
    cursor: pointer;
    height: 30px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 70px;
    border-radius: 5px 0px 0px 5px;
    & p {
      font-family: "Montserrat";
    }
  }
}
.form_dropdown {
  margin: 5px 0;
  .MuiAutocomplete-root {
    margin-bottom: 5px;
  }
}
.client_settings_export_pdf_div {
  margin-top: 15px;
  padding-top: 20px;
  background-color: white;
  width: 700px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  border-radius: 3px;
  flex-wrap: wrap;
  &-switch{
    display: flex;
    justify-content: space-between;
  }
}
.color_picker-preview {
  width: 20px;
  height: 20px;
  border-radius: 3px;
  border: 1px solid gray;
}
.client_settings-paragraph-picker {
  font-family: "Montserrat";
  background-color: white;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 30px;
  padding: 15px 15px 15px 35px;
  border-radius: 3px;
  flex-wrap: wrap;
  margin-top: 20px;
  & h1 {
    font-family: "Montserrat";
    font-size: 18px;
    font-weight: 500;
  }
  .client_settings-paragraphs {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0 15px;
    height: auto;
    flex-wrap: wrap;
    gap: 30px;
    .client_settings-paragraphInputs {
      display: flex;
      flex-direction: column;
      gap: 5px;
      & p {
        margin-top: 5px;
        margin-bottom: 5px;
        font-family: "Montserrat";
        font-size: 14px;
        font-weight: 500;
      }
      & textarea {
        width: 350px;
        border: solid 2px #f5f5f5;
        outline: none;
        border-radius: 3px;
        padding: 2px;
        height: 80px;
        overflow-y: scroll;
        resize: none;
        font-family: "Montserrat";
      }
    }
  }
  .attachments-field {
    min-width: 100px;
    align-self: flex-start;
  }
}
.client_settings_form{
  display: flex !important;
  gap: 20px;
  &_input{
    width: 50%;
  }
}
.second_client_settings-color_picker{
  width: 100% !important;
  padding: 0 !important;
}
.client_settings-color_picker {
  background-color: white;
  width: 700px;
  height: 280px;
  display: flex;
  padding-left: 80px;
  flex-direction: column;
  justify-content: center;
  border-radius: 3px;
  flex-wrap: wrap;
  & h1 {
    font-family: "Montserrat";
    font-size: 14px;
    font-weight: 500;
  }
  .instructions-example{
    font-weight: 400;
  }
  & textarea {
    width: 350px;
    border: solid 2px #f5f5f5;
    outline: none;
    border-radius: 3px;
    padding: 2px;
    height: 80px;
    overflow-y: scroll;
    resize: none;
    font-family: "Montserrat";
  }
  & p {
    margin-top: 5px;
    margin-bottom: 5px;
    font-family: "Montserrat";
    font-size: 13px;
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 5px;
    & input {
      width: 70px;
      border: 1px solid gray;
      outline: none;
      border-radius: 3px;
      padding: 2px;
    }
  }
}
.client_settings_switch_label {
  font-family: "Montserrat";
  font-size: 15px;
  font-weight: bold;
  color: #26353E;
}

// .css-1g66d9k-MuiSwitch-root .MuiSwitch-thumb {
//   color: white !important;
// }

@media (max-width: 1500px) {
  .edit__user__logo__div-clientSett {
    width: 500px;
  }
  .client_settings_export_pdf_div {
    width: 500px;
  }
  .client_settings-color_picker {
    width: 500px;
  }
}
@media (max-width: 700px) {
  .client_settings-paragraphInputs {
    flex-direction: column;
    & p{
      width: 80% !important;
    }
    & textarea{
      width: 80% !important;
    }
  }
}
.activeTab-container-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 30px;
  .accordions-wrapper{
    .MuiAccordionSummary-root {
      padding: 0 12px;
      width: 100%;
    }
  }
  &.contracts__wrapper {
    width: 100%;
  }
  &-title {
    color: $primary;
    font-family: "Montserrat";
    font-size: 20px;
    font-weight: 700;
  }
  .accordions-wrapper {
    display: flex;
    gap: 20px;
    & > * {
    .MuiAccordionDetails-root {
      height: 300px;
    }
    }
}
  .switch-wrapper {
    display: flex;
    margin-top: 20px;
    flex-direction: column;
    min-width: 250px;
    max-width: 350px;
    gap: 20px;
    .client_settings_export_pdf_div-switch {
      width: 100%;
      display: flex;
      align-items: center;
      gap: 10px;
      position: relative;
      .client_settings_switch_label {
        color: #252525;
        font-family: "Montserrat";
        font-size: 14px;
        font-weight: 600;
        .info-icon {
          display: flex;
          align-items: flex-start;
          margin-left: 5px;
          cursor: pointer;
          & svg {
            path:first-of-type {
              fill: #979797;
            }
          }
        }
      }
    }
  }
  .css-15v22id-MuiAccordionDetails-root{
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .MuiPaper-root{
    border-radius: 8px !important;
    box-shadow: 2px 3px 4px 0px #00000026 !important;
    border: none !important;
  }
  .css-1elwnq4-MuiPaper-root-MuiAccordion-root::before{
    display: none !important;
  }
  .MuiAccordionDetails-root{
    display: flex;
    justify-content: center;
  }
}
.accordion-childs{
 display: flex;
 justify-content: center;
 align-items: center;
 gap: 10px;
}

.accordion-title {
  color: #252525;
  font-family: "Montserrat";
  font-size: 14px;
  font-weight: 700;
}
.MuiAccordionSummary-root {
  border-bottom: 1px solid #E5E5E5;
}
@media screen and (max-width: 1000px) {
  .user__settings-container {
    display: flex;
    flex-direction: column;
    &-tabs {
      display: flex;
      margin: 0;
      padding-bottom: 20px;
      border-bottom: 1px solid #CECECE;
      .client-settings__tabs {
        display: flex;
        flex-direction: row;
        padding-right: 25px;
        height: fit-content;
        border-right: none;
      }
    }
  }
}
.switch-info__container {
  z-index: 999999;
  .ant-dropdown-menu {
    background-color: $primary;
    border-radius: 5px;
    padding: 0;
    &-item {
      font-size: 10px;
      font-family: "Montserrat";
      display: flex;
      align-items: center;
      gap: 5px;
      color: #fff;
      &:hover {
        background-color: transparent;
      }
      & svg {
        & > * {
          fill: $primary;
        }
         path:first-of-type {
           fill: #fff;
        }
      }
    }
  }
}

.email__container {
  display: flex;
  flex-direction: column;
  &-title {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 20px;
    & span {
      color: #252525;
      font-family: "Montserrat";
      font-size: 14px;
      font-weight: 600;
    }
  }
  &-inputs {
    display: flex;
    justify-content: space-between;
    & .form_input {
      width: 40%;
     &__label {
      color: #252525;
      font-family: "Montserrat";
      font-size: 12px;
      font-weight: 600;
     } 
      input {
        border: 1px solid #E5E5E5;
      }
    }
    & .textarea_container {
      width: 55%;
      .textarea__input {
        display: flex;
        flex-direction: column;
        height: 200px;
        .form_input__label {
          margin: 7px 0;
          color: #252525;
          font-family: "Montserrat";
          font-size: 12px;
          font-weight: 600;
        }
        textarea {
          border-radius: 8px;
          border: 1px solid #E5E5E5;
          padding: 10px 20px;
          resize: none;
          color: #979797;
          font-family: "Montserrat";
          font-size: 14px;
          font-weight: 500;
          height: 100%;
        }
      }
      &-bottom {
        display: flex;
        justify-content: space-between;
        color: #252525;
        font-family: "Montserrat";
        font-size: 14px;
        font-weight: 400;
      }
    }
  }
 &-actions {
  display: flex;
  margin-top: 20px;
  justify-content: flex-end;
  width: 100%;
  .primaryButton {
    border-radius: 8px;
    background: $primary;
    color: white;
    padding: 10px 40px;
  }
 }
}
.client__fields__container {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}
.custom__field__item-container {
  display: flex;
  align-items: center;
  border-radius: 8px;
  border: 1px solid #C1C1C1;
  padding: 5px 10px;
  .grab-icon {
    height: 100%;
    display: flex;
    align-items: center;
    margin-right: 10px;
    cursor: grab;
  }
  .field__title {
    border-radius: 4px;
    background: #F0F0F0;
    padding: 3px 8px;
    color: #979797;
    text-align: center;
    font-family: "Montserrat";
    font-size: 14px;
    font-weight: 600;
  }
  &-checkbox {
    label {
      margin-right: 0;
    }
  }
  &-actions {
    display: flex;
    gap: 10px;
    margin-left: 10px;
    & > * {
      width: 15px;
      height: 15px;
      cursor: pointer;
    }
  }
}
.clientFields__wrapper {
  .client__fields__breakLine {
    width: 100%;
    background: rgba(0, 0, 0, 0.10);
    height: 1px;
    margin: 20px 0;
  }
  &-title {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 20px;
   span {
    color: #252525;
    font-family: "Montserrat";
    font-size: 14px;
    font-weight: 600;
   }
  }
  &__filters {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 20px;
    &-container {
      display: flex;
      gap: 20px;
      .dropdown--container {
        padding: 5px 0;
        &-label {
          font-family: "Montserrat";
          font-size: 12px;
          font-weight: 600;
          margin-left: 1px;
          color: #252525;
          padding-bottom: 8.5px;
        }
        & .MuiAutocomplete-root {
          margin-bottom: 0;
          width: 180px;
        }
      }
      & .form_input {
        &__label {
          display: inline;
          margin-right: 10px;
          font-size: 12px;
          font-weight: 600;
          margin-left: 1px;
          color: #252525;
        }
        & .info__icon {
          cursor: pointer;
        }
        & input {
          height: 37px;
        }
      }
    }
    & .big__search__bar__container {
      margin-bottom: 5px;
    }
  }
  &-actions {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    padding-top: 20px;
    .primaryButton {
      border-radius: 8px;
      background: $primary;
      color: white;
      padding: 10px 40px;
    }
   }
   .client__fields__title {
    color: #C1C1C1;
    font-family: "Montserrat";
    font-size: 14px;
    font-weight: 600;
    padding-bottom: 15px;
   }
}
.contracts__accordion {
  min-width: 100% ;
  border-radius: 8px !important;
  box-shadow: 2px 3px 4px 0px rgba(0, 0, 0, 0.15) !important;
  .MuiAccordionDetails-root {
    min-height: fit-content !important;
    height: fit-content !important;
   
  }
}
.contracts__wrapper {
  display: flex;
  flex-direction: column;
  min-width: 100%;
 
  .accordions-wrapper-first{
    .MuiAccordionSummary-root {
      padding: 0 12px;
      width: 100%;
    }
  }
  .client_settings-color_picker-title {
    color: #252525;
    font-family: "Montserrat";
    font-size: 14px;
    font-weight: 600;
  }
  &-top {
    display: flex;
    justify-content: space-between;
    .client_settings-color_picker {
      border-left: 1px solid #CECECE;
    }
  }
  &-actions {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin-top: 20px;
    .primaryButton {
      border-radius: 8px;
      background: $primary;
      color: white;
      padding: 10px 40px;
    }
  }
}

.external__id__generator {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  &-example {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    &-title {
      color: #252525;
      font-family: "Montserrat";
      font-size: 14px;
      font-weight: 600;
    }
    div {
      ol {
        display: flex;
        flex-direction: column;
        gap: 6px;
        padding: 0 15px;
        & li::marker {
          font-weight: 600;
        }
      }
    }
    & p {
      display: flex;
      align-items: center;
      gap: 5px;
    }
  }
  & > * {
    width: 45%;
  }
  .input__wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    border: 1px dashed #979797;
    background: #F5F5F5;
   .form_input {
    width: 70%;
    &__label {
      color: #252525;
      font-family: "Montserrat";
      font-size: 14px;
      font-weight: 600;
    }
   } 
  }
}
.generated__pdf__container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  &-title {
    display: flex;
    gap: 10px;
    align-items: center;
    & span {
      color: #252525;
      font-family: "Montserrat";
      font-size: 14px;
      font-weight: 600;
    }
  }
  &-content {
    display: flex;
    align-items: center;
    margin: 20px 0;
    gap: 20px;
    color: #252525;
    font-family: "Montserrat";
    font-size: 14px;
    font-weight: 600;
    .dropdown--container {
      padding: 5px 0;
      &-label {
        font-family: "Montserrat";
        font-size: 12px;
        font-weight: 600;
        margin-left: 1px;
        color: #252525;
        padding-bottom: 8.5px;
      }
      & .MuiAutocomplete-root {
        margin-bottom: 0;
        width: 180px;
      }
    }
    & .form_input {
      &__label {
        font-size: 12px;
        font-weight: 600;
        margin-left: 1px;
        color: #252525;
        display: inline;
        margin-right: 10px;
      }
      & .info__icon {
        cursor: pointer;
      }
      & input {
        height: 37px;
      }
    }
  }
  &-actions {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    .primaryButton {
      border-radius: 8px;
      background: $primary;
      color: white;
      padding: 10px 40px;
    }
   }
}
.server__form {
  display: flex;
  flex-direction: column;
  gap: 20px;
  &-title {
    display: flex;
    gap: 10px;
    align-items: center;
    & span {
      color: #252525;
      font-family: "Montserrat";
      font-size: 14px;
      font-weight: 600;
    }
  }
  &-content {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    &-disabled__input {
     & input {
      background-color: #F5F5F5 !important;
     }
    }
    &-input , &-disabled__input {
      width: 45%;
     & input {
      border-radius: 5px;
      border: 1px solid #E5E5E5;
     }
    & .form_input__label {
      color: #252525;
      font-family: Montserrat;
      font-size: 14px;
      font-weight: 600;
    }
    }
    &-switch {
      display: flex;
      align-items: center;
      gap: 40px;
    }
  }
  &-actions {
    display: flex;
    justify-content: flex-end;
    gap: 20px;
    width: 100%;
    &-disabled__button {
      border-radius: 8px;
      background: #F5F5F5;
      border-radius: 8px;
      color: #252525;
      padding: 10px 40px;
      border: 1px solid #E5E5E5;
    }
    &-button {
      border-radius: 8px;
      background: $primary;
      color: white;
      padding: 10px 40px;
      display: flex;
      justify-content: center;
      border: 1px solid $primary;
      gap: 5px;
    }
   }
}

.client_settings_switch_label {
  color: #252525;
  font-family: "Montserrat";
  font-size: 14px;
  font-weight: 600;
  position: relative;
  .info-icon {
    display: flex;
    align-items: flex-start;
    margin-left: 5px;
    cursor: pointer;
    position: absolute;
    top: 0px;
    right: -20px;
    left: auto;
  }
}