.card__wrapper {
    width: 100%;
    height: 240px;
    border-radius: 12px;
    .selected-icon{
        top: -26px;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        left: 93%;
        width: 20px;
    }
    .alertsCards__container{
        position: relative;
        padding: 15px;
        height: 100%;
        border-radius: 12px;
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 600;
        line-height: 16px; 
        letter-spacing: 0.48px;
        display: flex;
        flex-direction: column;
        gap: 15px;
        cursor: pointer;
        &--title {
            display: flex;
            justify-content: space-between;
            align-items: center;
    
            &-text {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                display: inline-block; 
                max-width: 180px;
            }
    
            &-infoMore{
                display: flex;
                align-items: center;
                gap: 12px;
                &-dates {
                    border: 1px solid;
                    border-radius: 5px;
                    span {
                        font-family: "Montserrat";
                        font-size: 10px;
                        padding: 0 5px;
                    }
                }
                &-status {
                    border: 1px solid #979797;
                    border-radius: 5px;
                    width: 20px;
                    height: 20px;
                    cursor: pointer;
                    & span {
                        height: 100%;
                        width: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                }
            }
            
        }
    
        &--priority {
            display: flex;
            justify-content: space-between;
            
            &-firstEl{
                display: flex;
                justify-content: center;
                &-icon{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 3px;
                }
                &-line{
                    
                }
                &-text{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 3px;
                }
            }
    
            &-sedondEl{
                display: flex;
                gap: 25px;
                &-date{
                    border: 1px solid #C1C1C1;
                    border-radius: 4px;
                    padding: 5px;
                    background: #EFEFEF;
    
                    span{
                        color: #C1C1C1;
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: normal;
                    }
    
                }
                &-priority{
                    display: flex;
                    justify-content: center;
                    gap: 5px;
                    font-size: 12px;
                    background: #fff;
                    border-radius: 4px;
                    width: fit-content;
                    padding: 4px;
                    align-items: center;
                }
                }
            
        }
    
    
        .progress-wrapper {
            display: flex;
            flex-direction: column;
            width: 100%;
          }
          
          .progress-bar {
            position: relative;
            height: 30px;
            border-radius: 100px;
            background-color: #fff;
            border: 1px solid #fff;
            width: 100%;
            margin-bottom: 10px;
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 3px;
            -webkit-box-shadow: inset 1px 1px 4px 0px rgba(0,0,0,0.15);
            -moz-box-shadow: inset 1px 1px 4px 0px rgba(0,0,0,0.15);
            box-shadow: inset 1px 1px 4px 0px rgba(0,0,0,0.15);
            font-weight: 500;
          }
          
          .filler {
            height: 100%;
            border-radius: 20px;
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            transition: width 0.3s ease;
          
            .high-progress {
              color: #fff;
              margin-right: 4px;
                font-size: 12px;
                margin-top: 3px;

            }
          }
          
          .percentage {
            position: absolute;
            top: 6px;
            margin-left: 5px;
            font-size: 12px;
            color: #000; 
          }
          
          .count {
            font-size: 12px;
            font-weight: 500;
            color: #000; 
          }
          
    
        &--description{
            text-align: justify;
            font-size: 13px;
            font-style: normal;
            font-weight: 500;
            line-height: 18px;     
            word-break: break-word;
            display: -webkit-box;
            -webkit-line-clamp: 4;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}

//Goal Information Card
.goalInfo__container{
    width: 400px;
    height: auto;
    font-family: "Montserrat";
    font-size: 12px;
    font-weight: 500;
    line-height: 16px; /* 160% */
    letter-spacing: 0.3px;
    padding: 10px 10px !important;

    & .ant-dropdown-menu {
        border-radius: 8px !important;
    }
    &--title{
        display: flex;
        align-items: center;
        gap: 10px;
        color: #252525;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 16px; 
        letter-spacing: 0.36px;
        margin-bottom: 11px;
    }

    &--selectedTarget{
        display: flex;
        flex-direction: column;
        gap: 10px;
        max-height: 300px;
        overflow: scroll;
    }

    &--selectType{
        display: flex;
        flex-direction: column;
        gap: 10px;

        span {
            display: flex;
            gap: 3px;
        }
    }

    &--statusType ,&--dateType{
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: 3px;
    }

    

    &--breakLine{
        display: flex;
        justify-content: center;
        margin: 10px 0px 10px 0;
         &-line{
            width: 100%;
            border-bottom: 0.5px solid #C1C1C1;
         }
    }
    &--priority {
        display: flex;
        align-items: center;
        gap: 3px;
        .show-wrapper{
            display: flex;
            align-items: center;
            gap: 8px;
            font-weight: 600;
            &_item{
                display: flex;
                align-items: center;
                gap: 4px;
            }
        }
        .priority__container {
            border: 1px solid;
            border-radius: 4px;
            display: flex;
            align-items: center;
            gap: 5px;
            padding: 3px;
            & .icon {
                height: 100%;
                display: flex;
                align-items: center;
                svg {
                    height: 12px;
                    width: 12px;
                }
            }
            & .label {
                font-family: "Montserrat";
                font-size: 12px;
                font-weight: 600;
            }
        }
    }
}

.bold__values{
    display: flex;
    align-items: center;
    gap: 3px;
    color: #252525;
    font-family: "Montserrat";
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0.3px;
    word-break: break-all;

}

.alertsCards__container__popover{
    & ul {
        // width: 226px;
        width: auto;
        padding: 5px;
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        gap: 5px;
        .no-actions {
            font-weight: 600;
            span {
              color: $primary;
              font-weight: 600;
            }
          }
          .ant-dropdown-menu-title-content{
            font-weight: 600;
          }
      .ant-dropdown-menu-item {
          display: flex;
          gap: 10px;
          cursor: default;
          font-size: 14px;
          border-radius: 6px;
        &:hover {
            background-color: $secondaryhover;
          cursor: pointer;
        }
      }
    }
}
.expired__goal-box-time {
    display: flex;
    flex-direction: row;
    gap: 10;
    align-items: center;
    .time {
        color: #979797;
    }
}